import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { Plog } from '@gpeel/plog';

export interface BearerTokenResponse {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

export interface AuthInfos {
  email: string;
  password: string;
}

const { apiUrl } = environment;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private ENDPOINT_URLS = {
    LOGIN: `${apiUrl}/login`,
    REFRESH: `${apiUrl}/refresh`,
    GET_MY_ROLES: `${apiUrl}/api/Account/GetMyRoles`,
  };

  refreshOrLogoutTimeout: any = 0;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    this.scheduleRefreshOrLogout();
  }

  login(clientInfos: AuthInfos) {
    Plog.debug('login');
    return this.http
      .post<BearerTokenResponse>(this.ENDPOINT_URLS.LOGIN, clientInfos)
      .pipe(
        tap({
          next: (bearerTokenResponse) =>
            this.handleBearerResponse(bearerTokenResponse),
        }),
        switchMap(() =>
          this.http.get<[string]>(this.ENDPOINT_URLS.GET_MY_ROLES)
        ),
        tap({
          next: ([role]) => {
            this.localStorage.set('userRole', role);
          },
        })
      );
  }

  refresh() {
    Plog.debug('refresh');
    const refreshToken = this.localStorage.get('refreshToken');

    return this.http
      .post<BearerTokenResponse>(this.ENDPOINT_URLS.REFRESH, { refreshToken })
      .pipe(
        tap({
          next: (bearerTokenResponse) =>
            this.handleBearerResponse(bearerTokenResponse),
        }),
        switchMap(() =>
          this.http.get<[string]>(this.ENDPOINT_URLS.GET_MY_ROLES)
        ),
        tap({
          next: ([role]) => {
            this.localStorage.set('userRole', role);
          },
        })
      );
  }

  handleBearerResponse({
    accessToken,
    expiresIn,
    refreshToken,
  }: BearerTokenResponse) {
    Plog.debug('handleBearerResponse');
    // expiresIn = 20; // TEST
    this.localStorage.set('accessToken', accessToken);
    this.localStorage.set('createdAt', Math.floor(new Date().getTime() / 1000));
    this.localStorage.set('expiresIn', expiresIn);
    this.localStorage.set('refreshToken', refreshToken);

    this.scheduleRefreshOrLogout();
  }

  scheduleRefreshOrLogout() {
    Plog.debug('scheduleRefreshOrLogout');

    let createdAt: string | number | null = this.localStorage.get('createdAt');
    let expiresIn: string | number | null = this.localStorage.get('expiresIn');

    if (createdAt === null || expiresIn === null) {
      Plog.debug('nothing to schedule');
      return;
    }

    createdAt = parseInt(createdAt);
    expiresIn = parseInt(expiresIn);
    const now = Math.floor(new Date().getTime() / 1000);
    console.log(Math.max(createdAt + expiresIn - now - 10, 0) * 1000);
    clearTimeout(this.refreshOrLogoutTimeout);
    this.refreshOrLogoutTimeout = setTimeout(
      () => this.refreshOrLogout(),
      Math.max(createdAt + expiresIn - now - 60, 0) * 1000
      //   Math.max(createdAt + expiresIn - now - 10, 0) * 1000 // TEST
    );
  }

  refreshOrLogout() {
    Plog.debug('refreshOrLogout');

    if (this.checkTokenValidity()) {
      this.refresh().subscribe();
    } else {
      this.logout();
    }
  }

  checkTokenValidity() {
    let createdAt: string | number | null = this.localStorage.get('createdAt');
    let expiresIn: string | number | null = this.localStorage.get('expiresIn');

    if (createdAt === null || expiresIn === null) {
      return false;
    }

    createdAt = parseInt(createdAt);
    expiresIn = parseInt(expiresIn);

    const now = Math.floor(new Date().getTime() / 1000);
    Plog.debug(
      'now : ' + now,
      'createdAt : ' + createdAt,
      'expiresIn : ' + expiresIn,
      'createdAt + expiresIn : ' + (createdAt + expiresIn),
      now < createdAt + expiresIn
    );

    return now < createdAt + expiresIn;
  }

  logout() {
    Plog.debug('logout');
    this.localStorage.remove('accessToken');
    this.localStorage.remove('createdAt');
    this.localStorage.remove('expiresIn');
    this.localStorage.remove('refreshToken');
    this.localStorage.remove('role');
    this.localStorage.remove('userRole');
  }

  get isLoggedIn() {
    return this.checkTokenValidity();
  }

  get token() {
    return this.localStorage.get('accessToken');
  }

  get role() {
    return this.localStorage.get('userRole');
  }
}
