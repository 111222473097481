import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SigninComponent } from '@app/screens/authentication/signin/signin.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // Check if the current route isn't the login page route
          if (!this.isLoginComponentActive()) {
            // Auto logout for other 401 errors
            this.authenticationService.logout();
            location.reload();
          }
        }
        if (err.status === 403) {
          // Handle 403 error
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Vous n'avez pas le rôle requis pour effectuer cette action!",
          }).then( () => {
            this.router.navigate(['/dashboard']);
          });
        }
        return throwError(err);
      })
    );
  }
  private isLoginComponentActive(): boolean {
    let snapshot: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;

    while (snapshot) {
      if (snapshot.component && snapshot.component === SigninComponent) {
        return true;
      }
      snapshot = snapshot.firstChild;
    }
    return false;
  }
}
