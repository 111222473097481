import { UnsubscribeOnDestroyAdapter } from '@shared';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class Store<T> extends UnsubscribeOnDestroyAdapter {
  private _state$: BehaviorSubject<T>;
  public readonly state$: Observable<T>;

  protected constructor(initialState: T) {
    super();
    this._state$ = new BehaviorSubject<T>(initialState);
    this.state$ = this._state$.asObservable();
  }

  protected get state(): T {
    return this._state$.getValue();
  }

  protected setState(nextState: T): void {
    this._state$.next(nextState);
  }
}
