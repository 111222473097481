import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'clients',
    title: 'MENUITEMS.CLIENTS.TEXT',
    icon: 'users',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'commands',
    title: 'MENUITEMS.COMMANDS.TEXT',
    icon: 'shopping-cart',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'providers',
    title: 'MENUITEMS.PROVIDERS.TEXT',
    icon: 'camera',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'products',
    title: 'MENUITEMS.PRODUCTS.TEXT',
    icon: 'briefcase',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'accounts',
    title: 'MENUITEMS.ACCOUNTS.TEXT',
    icon: 'settings',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'emails',
    title: 'MENUITEMS.EMAIL.TEXT',
    icon: 'mail',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'finances',
    title: 'MENUITEMS.FINANCE.TEXT',
    icon: 'command',
    class: '',
    groupTitle: false,
    submenu: [],
  }
];
