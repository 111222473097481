export interface Notification {
  id: number;
  title: string;
  description: string;
  type: string;
  targetUrl: string;
  read: boolean;
  date: string;
  appUserId: string;
  appUser: number;
}

export class NotificationState {
  notifications: Notification[] = [];
}
