import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@app/core/utils/store';
import { Notification, NotificationState } from '@app/models/notification';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

const { apiUrl } = environment;

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends Store<NotificationState> {
  private readonly GET_ALL_NOTIFICATIONS_URL = `${apiUrl}/api/Notification/GetAllNotifications`;
  private readonly CHANGE_NOTIFICATIONS_TO_READ_URL = `${apiUrl}/api/Notification/ChangeNotificationToRead`;
  private readonly REMOVE_NOTIFICATIONS_URL = `${apiUrl}/api/Notification/RemoveNotification`;

  public isLoading = false;

  get notifications(): Notification[] {
    return this.state.notifications;
  }

  set notifications(newNotifications: Notification[]) {
    this.state.notifications = newNotifications;
    this.setState(this.state);
  }

  get notifications$(): Observable<Notification[]> {
    return this.state$.pipe(map((state) => state.notifications));
  }

  constructor(private httpClient: HttpClient, private router: Router) {
    super(new NotificationState());
  }

  subscriberErrorHandler = (error: Error) => {
    this.isLoading = false;
    console.log(error);
  };

  getAllNotifications() {
    this.isLoading = true;
    this.subs.sink = this.httpClient
      .get<Notification[]>(`${this.GET_ALL_NOTIFICATIONS_URL}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(() => new Error(error.name + ' ' + error.message))
        )
      )
      .subscribe({
        next: (data) => {
          this.isLoading = false;
          this.notifications = data;
        },
        error: this.subscriberErrorHandler,
      });
  }

  changeNotificationToRead(id: number): void {
    this.isLoading = true;
    this.httpClient
      .put(this.CHANGE_NOTIFICATIONS_TO_READ_URL + '?notificationId=' + id, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => new Error(error.name + ' ' + error.message));
        })
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.getAllNotifications();
        },
        error: this.subscriberErrorHandler,
      });
  }

  removeNotification(id: number): void {
    this.isLoading = true;
    this.httpClient
      .delete(this.REMOVE_NOTIFICATIONS_URL + '?notificationId=' + id, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => new Error(error.name + ' ' + error.message));
        })
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.getAllNotifications();
        },
        error: this.subscriberErrorHandler,
      });
  }
}
