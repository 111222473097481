import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ConfigService } from '@config';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import {
  InConfiguration,
  AuthService,
  WINDOW,
  LanguageService,
  RightSidebarService,
} from '@core';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { FeatherIconsComponent } from '../../shared/components/feather-icons/feather-icons.component';
import { MatButtonModule } from '@angular/material/button';
import { WINDOW_PROVIDERS } from '@core/service/window.service';
import { NotificationService } from '@app/services/notification.service';
import { Observable, map } from 'rxjs';
import moment from 'moment';

// interface Notifications {
//   message: string;
//   time: string;
//   icon: string;
//   color: string;
//   status: string;
// }

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    MatButtonModule,
    FeatherIconsComponent,
    MatMenuModule,
    NgScrollbar,
    AsyncPipe,
  ],
  providers: [RightSidebarService, WINDOW_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  public config!: InConfiguration;
  isNavbarCollapsed = true;
  isNavbarShow = true;
  flagvalue: string | string[] | undefined;
  countryName: string | string[] = [];
  langStoreValue?: string;
  defaultFlag?: string;
  isOpenSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private notificationService: NotificationService
  ) {
    super();
    this.notificationService.getAllNotifications();
  }
  listLang = [
    { text: 'Français', flag: 'assets/images/flags/fr.jpg', lang: 'fr' },
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  ];

  get notifications$(): Observable<any> {
    moment.locale('fr');
    return this.notificationService.notifications$.pipe(
      map((notifications) => {
        return notifications
          .map((notification) => {
            const notif = {
              ...notification,
              message: notification.description,
              datetimeISO: notification.date,
              time: moment(notification.date).fromNow(),
              icon: 'mail',
              color: 'nfc-blue',
              status: notification.read ? 'msg-read' : 'msg-unread',
            };

            switch (notification.type) {
              case 'ChangePwd':
                notif.icon = 'person_add';
                break;
              default:
                notif.icon = 'mail';
            }

            return notif;
          })
          .sort((a, b) => {
            return new Date(a.datetimeISO) < new Date(b.datetimeISO) ? 1 : -1;
          });
      })
    );
  }

  markToRead(id: number) {
    this.notificationService.changeNotificationToRead(id);
  }

  //     readAll() {
  //       this.notificationService.changeNotificationToRead(id);
  //   }

  removeNotification(id: number) {
    this.notificationService.removeNotification(id);
  }

  //   notifications: Notifications[] = [
  // {
  //   message: 'Please check your mail',
  //   time: '14 mins ago',
  //   icon: 'mail',
  //   color: 'nfc-green',
  //   status: 'msg-unread',
  // },
  // {
  //   message: 'New Patient Added..',
  //   time: '22 mins ago',
  //   icon: 'person_add',
  //   color: 'nfc-blue',
  //   status: 'msg-read',
  // },
  // {
  //   message: 'Your leave is approved!! ',
  //   time: '3 hours ago',
  //   icon: 'event_available',
  //   color: 'nfc-orange',
  //   status: 'msg-read',
  // },
  // {
  //   message: 'Lets break for lunch...',
  //   time: '5 hours ago',
  //   icon: 'lunch_dining',
  //   color: 'nfc-blue',
  //   status: 'msg-read',
  // },
  // {
  //   message: 'Patient report generated',
  //   time: '14 mins ago',
  //   icon: 'description',
  //   color: 'nfc-green',
  //   status: 'msg-read',
  // },
  // {
  //   message: 'Please check your mail',
  //   time: '22 mins ago',
  //   icon: 'mail',
  //   color: 'nfc-red',
  //   status: 'msg-read',
  // },
  // {
  //   message: 'Salary credited...',
  //   time: '3 hours ago',
  //   icon: 'paid',
  //   color: 'nfc-purple',
  //   status: 'msg-read',
  // },
  //   ];

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    // if (offset > 50) {
    //   this.isNavbarShow = true;
    // } else {
    //   this.isNavbarShow = false;
    // }
  }
  ngOnInit() {
    this.config = this.configService.configData;
    this.docElement = document.documentElement;
    this.langStoreValue = localStorage.getItem('lang') as string;
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/fr.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/authentication/signin']);
  }
  navigateToCarto() {
    this.router.navigate(['/dashboard/cartographie']);
  }
}
