import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }
        if (err.status === 403) {
          // Handle 403 error
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Vous n'avez pas le rôle requis pour effectuer cette action!",
          }).then( () => {
            this.router.navigate(['/dashboard']);
          });
        }
        return throwError(err);
      })
    );
  }
}
